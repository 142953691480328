import { useMemo } from 'react';
import { GalleryWithProgress, useMedias } from '@divlab/divanui';

import Header from './elements/Header';
import Card from './elements/Card';
import styles from './InStock.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { CategoriesInStockData } from '@Pages/PageIndex/typings';

type NestedItemsList = CategoriesInStockData[][] | CategoriesInStockData[];

interface InStockProps extends HTMLAttributes<HTMLDivElement> {
  data: CategoriesInStockData[];
  mainLink?: string;
}

const InStock: FC<InStockProps> = (props) => {
  const { data, mainLink, ...restProps } = props;
  const { isDesktopL } = useMedias();

  const nestedData: NestedItemsList = useMemo(() => {
    const items = [];
    let nestedArr = [];

    data.forEach((item, idx) => {
      if (nestedArr.length === 2) {
        items.push(nestedArr);
        items.push(item);
        nestedArr = [];
        return;
      }
      nestedArr.push(item);
      if (idx === data.length - 1) {
        items.push(nestedArr);
      }
    });

    return items;
  }, [data]);

  const findIndex = (item: CategoriesInStockData) => {
    return data.findIndex((card) => card.name === item.name);
  };
  const getKey = (item: CategoriesInStockData | CategoriesInStockData[]) => {
    return Array.isArray(item) ? item[0].name : item.name;
  };

  const renderItems = () => {
    return nestedData.map((item: CategoriesInStockData | CategoriesInStockData[]) => (
      <div key={getKey(item)} className={styles.cardWrapper}>
        {Array.isArray(item) ? (
          <div className={styles.nestedCard}>
            {item.map((nested) => (
              <Card idx={findIndex(nested)} key={nested.name} card={nested} />
            ))}
          </div>
        ) : (
          <Card idx={findIndex(item)} key={item.name} card={item} />
        )}
      </div>
    ));
  };

  return (
    <div {...restProps} className={styles.container}>
      <Header className={styles.header} mainLink={mainLink} />
      <GalleryWithProgress hasTrack={!isDesktopL} cnTrack={styles.galleryTrack}>
        {renderItems()}
      </GalleryWithProgress>
    </div>
  );
};

export default InStock;
